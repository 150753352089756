html,
body {
	/*background-color: lightblue;
	background-repeat: no-repeat;
	background-image: url(images/bg.jpg);
	background-size: cover;*/
	background-color: lightblue;
}

a {
	color: #fff;
}

.full,
.blob {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
}

.blob {
}

.bg {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	/*background-color: lightblue;
	background-repeat: no-repeat;
	background-image: url(images/bg.jpg);
	background-size: cover;
	background-color: lightblue;
	background-position: center center;*/
	/*background-color: white;*/
	background: linear-gradient(#fff 50%, #e7e7e7 100%);
	clip-path: url(#clip);
}

.circle {
	width: 30vw;
	height: 30vw;
	/*background: yellow;*/
	border-radius: 50%;
	position: absolute;
	overflow: hidden;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.circle img {
	width: 90%;
	height: 90%;
	animation: spin 30s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}

@media (max-width: 768px) {
	.circle {
		width: 75vw;
		height: 75vw;
	}
}

.logo {
	height: 50px;
	position: absolute;
	bottom: 20px;
	left: 20px;
	z-index: 3;
}

.logo img {
	height: 100%;
}

.directions {
	position: absolute;
	bottom: 20px;
	right: 20px;
	z-index: 3;
	font-size: 22px;
	mix-blend-mode: difference;
}

.top {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	border-bottom: 1px solid #fff;
	color: #fff;
	z-index: 3;
	font-size: 18px;
	mix-blend-mode: difference;
}

.marq {
	padding: 10px;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: white;
	z-index: 999;
	transform: translateY(100%);
	transition: transform 0.5s;
}

.modal-open {
	transform: translateY(0);
}
